import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";

export default function Career() {
    useEffect(() => {
        document.title = "Daily Bounty Careers"
    })

  return (
    <Box id="career-page"
      width="100%"
      marginTop="60px"
      backgroundColor="white"
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Box
        id="career-page-top-full-section"
        display="flex"
        justifyContent="center"
        paddingTop="3vh"
        paddingBottom="3vh"
        sx={{
          width: "100%",
          backgroundImage: "BACKGROUND IMAGE HERE",
        }}
      >
        <Box
          id="career-page-top-content-section"
          sx={{
            width: { xs: "85%", sm: "85%", md: "60%", lg: "60%" },
          }}
        >
          <Box
            id="career-page-title"
            sx={{
              height: "150px",
              textAlign: "center",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: { xs: "50px", sm: "65px" },
              paddingTop: { xs: "40px", sm: "70px" },
            }}
          >
            Join Us at Daily Bounty
          </Box>
          <Box
            id="career-page-subtitle"
            textAlign="center"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            fontSize="20px"
            paddingTop="20px"
            paddingBottom="20px"
          ></Box>
          <Box
            id="see-open-positions"
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
              },
              alignItems: "center",
              fontSize: "20px",
              paddingBottom: "40px",
            }}
          >
            <Button
              sx={{
                fontSize: { xs: "25px", sm: "30px" },
              }}
            >
              see open positions
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        id="career-page-bottom"
        width="100%"
        height="100%"
        paddingTop="5vh"
        backgroundColor="white"
        display="flex"
        justifyContent="center"
      >
        <Box
          id="career-page-bottom-content"
          sx={{
            width: { xs: "90%", sm: "90%", md: "60%", lg: "60%" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            id="career-page-left-side"
            sx={{
              width: { xs: "100%", sm: "100%", md: "48%" },
            }}
          >
            <Box
              id="our-company-title"
              textAlign="center"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              fontSize="65px"
              marginBottom="0.5em"
              sx={{}}
            >
              Our Vision
            </Box>
            <Box
              id="our-company-content"
              textAlign="left"
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="25px"
              marginBottom="1em"
            >
              At the Daily Bounty, our goal is to reduce food waste. By bundling
              leftovers into bounties, we give the resturant an monetary
              incentive to reduce waste and the customer an unbeatable deal.
            </Box>
            <Box
              id="our-company-title"
              sx={{
                textAlign: {
                  xs: "left",
                  sm: "left",
                  md: "left",
                  lg: "left",
                  xl: "center",
                },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "65px",
                marginBottom: "0.5em",
              }}
            >
              A Bountiful Career
            </Box>
            <Box
              id="our-company-content"
              textAlign="left"
              justifyContent="center"
              alignItems="center"
              fontSize="25px"
              marginBottom="1em"
            >
              Daily bounty is a startup based out of North Carolina. We work
              with local businesses as well as chain resturants directly and
              maintain a very hands on approach to community
              interaction/feedback.
              <Box height="1em"></Box>
              Currently, we are not specifically hiring for roles. However for
              those interested, reach out at example@email.com
            </Box>
          </Box>
          <Box
            id="career-page-right side"
            sx={{
              width: { xs: "0", sm: "0", md: "48%", lg: "48%" },
              height: "90%",
              backgroundImage:
                "url('https://down2earthinteriordesign.com/wp-content/uploads/2022/06/sustainable_restaurant_design_tin_ceiling.jpg')",
              backgroundRepeat: "no-repeat",
              display: { xs: "none", sm: "none", md: "inline-block" },
            }}
          ></Box>
        </Box>
      </Box>
      <Box id="career-page-bottom-section"
        sx={{

        }}
      >
        <Box id="career-page-bottom-section-title"
          sx={{
            fontSize: "3em",
            color: "#785a41"
          }}
        > 
          Open Positions
        </Box>
        <Box id="career-page-bottom-positions-list"
          sx={{
            border: "1px solid black",
            width: "60vw",
            height: "50vh"
          }}
        > 

        </Box>
      </Box> 
    </Box>
  );
}
