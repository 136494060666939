import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  zip: "27519",
};

export const zipSlice = createSlice({
  name: "zipcode",
  initialState,
  reducers: {
    setZip: (state, action) => {
      state.zip = action.value;
    },
  },
});

export const { setZip } = zipSlice.actions;

export default zipSlice.reducer;
