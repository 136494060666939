import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Item from "../components/Item";

export default function Search() {
  const [filter, setFilter] = useState(0);
  const numResults = "608 Results";

  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  return (
    <Box
      width="100%"
      marginTop="60px"
      padding="20px"
      backgroundColor="background.light"
    >
      <Box width="90%" margin="auto">
        <FormControl>
          <InputLabel>Filter:</InputLabel>
          <Select
            value={filter}
            label="Filter"
            onChange={handleChange}
            size="small"
          >
            <MenuItem value={0}>Distance</MenuItem>
            <MenuItem value={1}>Price</MenuItem>
            <MenuItem value={2}>Rating</MenuItem>
            <MenuItem value={3}>Recommended</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h4" pt="20px">
          {numResults}
        </Typography>
        <Box
          pt="25px"
          display="grid"
          gridTemplateColumns={{
            sm: "repeat(auto-fill, 300px)",
            md: "repeat(3, 1fr)",
          }}
          justifyContent="space-around"
          rowGap="20px"
          columnGap="1.33%"
        >
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
        </Box>
      </Box>
    </Box>
  );
}
