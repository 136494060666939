import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

export default function Career() {
  
    useEffect(() => {
        document.title = "Daily Bounty Impact"
    })
  
    return (
    <Box id="impact-page"
        sx={{
            width: "100%",
            paddingBottom: "60px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        }}
    >
        <Box id="impact-content"
            sx={{
                marginTop: "40px",
            }}
        >
            <Box id="impact-content-top-section"
                sx={{   
                    width: "99vw",
                    height: "350px",
                    paddingTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid black",
                    backgroundImage: "linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)),url('https://media.timeout.com/images/105501076/1024/576/image.webp')",
                }}
            >
                <Box id="impact-content-top-section-title"
                    sx={{
                        fontSize: "70px",
                        fontWeight: "bold",
                        color: "whitesmoke"
                    }}
                >
                    Our Impact
                </Box>
            </Box>
            <Box id='impact-content-second-section' 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",

                }}
            >
                <Box id='impact-content-who-we-are'
                    sx={{
                        width: "40vw",
                        marginTop: "30px"
                    }}
                >
                    <Box id='impact-content-who-we-are-title'
                        sx={{
                            width: "50%",
                            fontSize: "2.5em",
                            fontWeight: "bold",
                            fontFamily: "Oswald",
                            color: "#785a41",
                            marginBottom: "20px"
                        }}
                    >
                        Who We Are
                    </Box>
                    <Box id='impact-content-who-we-are-content'
                        sx={{
                            fontSize: "1.3em"
                        }}
                    >
                        The Daily Bounty was founded and built to reduce food waste. Founded in 2024, we
                        have been commited to innovating and implementing new ways of reselling, reusing 
                        and recycling grocery store and resturant waste. 
                        <br></br>
                        <br></br>
                        We acknowledge the enormous impact the food industry has on the environment and 
                        are thus tasked with coming up with new ways to alleviate this growing problem, for
                        ourselves and many generations to come. 
                    </Box>
                </Box>
                <Box id='impact-content-values'
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}     
                >
                    <Box id='impact-content-values-title'
                        sx={{
                            fontSize: "2.5em",
                            fontWeight: "bold",
                            fontFamily: "Oswald",
                            color: "#785a41",
                            marginTop: "50px",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}
                    >
                        Our Values
                    </Box>
                    <Grid 
                        container 
                        spacing={2}
                        sx={{
                            width: "35vw",
                            marginBottom: "60px"
                        }}
                    >
                        <Grid item
                            xs={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Box id="tile-content"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box id="tile-title"
                                    sx={{
                                        fontSize: "1.3em",
                                        fontWeight: "bold",
                                        fontFamily: "Oswald",
                                        marginBottom: "10px",
                                    }}
                                >
                                    To Protect and Sustain the Earth
                                </Box>
                                <Box id="tile-content-text"
                                    width= "15vw"
                                    textAlign="justify"
                                >
                                    At The Daily Bounty, are committed to make the food industry 
                                    more sustainable. This includes working in resturants, grocers,
                                    or any business avenue where we can make an impact on how food
                                    can go to waste. 
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item
                            xs={6}
                            sx={{
                            }}
                        >
                            <Box id="tile-content"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box id="tile-title"
                                    sx={{
                                        fontSize: "1.3em",
                                        fontWeight: "bold",
                                        fontFamily: "Oswald",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Quality and Safety
                                </Box>
                                <Box id="tile-content-text"
                                    width= "15vw"
                                    textAlign="justify"

                                >
                                    Keeping our products high quality and safe is essential in 
                                    food distribution. We are commited to ensuring that our Bounties
                                    are safe and affordable. 
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item
                            xs={6}
                            sx={{
                            }}
                        >
                            <Box id="tile-content"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box id="tile-title"
                                    sx={{
                                        fontSize: "1.3em",
                                        fontWeight: "bold",
                                        fontFamily: "Oswald",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Authenticity and Transparency
                                </Box>
                                <Box id="tile-content-text"
                                    width= "15vw"
                                    textAlign="justify"

                                >
                                    Making sure that our customers fully know what they're buying
                                    and who we are as a company is core to our values. We commit 
                                    to maintaining transparency in our company growth.
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item
                            xs={6}
                            sx={{
                            }}
                        >
                            <Box id="tile-content"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box id="tile-title"
                                    sx={{
                                        fontSize: "1.3em",
                                        fontWeight: "bold",
                                        fontFamily: "Oswald",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Movement in the Community
                                </Box>
                                <Box id="tile-content-text"
                                    width= "15vw"
                                    textAlign="justify"

                                >
                                    Building a more sustainable world is not done with one company
                                    or one team, it is humans coming together for a common goal. 
                                    Thus at the Daily Bounty we are commited to driving forward
                                    community action in all ways sustainable.
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box id='impact-page-bottom-image'
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                        src="https://images.unsplash.com/photo-1500382017468-9049fed747ef?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        style={{
                            width: '50%',
                        }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
  );
}