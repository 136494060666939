import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React from "react";

export default function Item() {
  return (
    <Card maxHeight="300px" sx={{ backgroundColor: "#ffffff" }}>
      <CardMedia
        component="img"
        height="200px"
        image="https://images.unsplash.com/photo-1509042239860-f550ce710b93?q=80&w=1974
        &auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVuf
        DB8fHx8fA%3D%3D"
        alt={"cafe"}
        sx={{ objectFit: "cover" }}
      />
      <CardContent>
        <Typography variant="h5" color="#785a41">
          Cafe Enchante
        </Typography>
        <Typography variant="h5" color="#000000">
          1.2 mi
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" color="#000000">
            5.0 ⭐
          </Typography>
          <Box display="flex">
            <Typography variant="h5" pr="10px">
              <s>$12.99</s>
            </Typography>
            <Typography variant="h5" color="#785a41">
              $3.99
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
