import React, { useEffect } from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";

export default function Legal() {
  useEffect(() => {
    document.title = "Daily Bounty Legal";
  });

  const [language, setLanguage] = React.useState("English");

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Box
      paddingRight="0"
      backgroundColor="background.light"
      width="100%"
      marginTop="60px"
      overflowY="scroll"
      display="flex"
      justifyContent="center"
    >
      <Box
        width="60%"
        marginTop="60px"
        marginBottom="60px"
        display="block"
        sx={{
          "@media screen and (max-width: 768px)": {
            width: "85%",
          },
        }}
      >
        <Typography
          gutterBottom
          textAlign="center"
          variant="body1"
          fontWeight="bold"
        >
          Terms and Conditions
        </Typography>
        <Typography textAlign="left" variant="body1">
          <br />
          Effective: Jan 1, 2050
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut congue ex
          quis dolor commodo, in finibus ipsum fermentum. Vestibulum non
          molestie purus. Nullam accumsan velit massa, eu cursus nisi auctor
          nec. Sed sit amet elit pellentesque magna malesuada elementum sed in
          odio. Pellentesque commodo, dolor in semper volutpat, eros libero
          pellentesque urna, ac pellentesque ex elit a mauris. Nunc rutrum lorem
          nulla, at maximus libero varius a. Fusce vitae ipsum nec nisi
          efficitur placerat ac fringilla nisl. Mauris maximus sem sed dolor
          vehicula, et ultricies arcu tempus. Duis sodales pellentesque
          eleifend. Nam lorem metus, faucibus sit amet turpis vel, egestas
          fermentum nisl. Praesent faucibus sapien luctus diam convallis
          convallis.
          <br />
          <br />
          Nulla ut ultrices odio. Sed dictum vulputate enim et ullamcorper.
          Vivamus eros sem, facilisis at mauris blandit, gravida commodo urna.
          Sed tincidunt ex vitae odio fringilla egestas. Maecenas felis sapien,
          molestie vel egestas eget, sodales eu odio. Morbi mattis, mauris ac
          sollicitudin efficitur, nisi orci hendrerit nisl, in placerat tellus
          nibh sed eros. Mauris dapibus ipsum vel purus tincidunt iaculis.
          Curabitur eu posuere elit, ac efficitur quam. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Sed nibh lorem, porta id pretium
          euismod, fringilla ac massa. Proin fringilla in tortor vitae placerat.
          Phasellus semper, diam et ullamcorper bibendum, orci neque placerat
          nisi, pellentesque finibus tellus ante in ipsum. Aenean in nisi
          fringilla ex luctus placerat. Quisque efficitur nisl risus, nec
          viverra tortor euismod sit amet.
          <br />
          <br />
          Vivamus scelerisque pretium libero, et vulputate sem tincidunt ut. In
          sed varius felis. Donec vehicula iaculis justo, id finibus enim
          gravida ut. Phasellus sodales dolor id erat tristique, quis gravida
          orci tincidunt. Mauris elit tellus, tempus ut nibh quis, mattis
          convallis ligula. Mauris rutrum libero et arcu auctor, a maximus mi
          faucibus. Vivamus vitae odio efficitur, ultrices diam eget, ultrices
          odio. Sed mollis vel leo et egestas. Morbi efficitur dolor eget
          egestas facilisis. Ut libero risus, ullamcorper vel nulla semper,
          faucibus iaculis mi. Vestibulum semper, libero sit amet varius
          consectetur, massa massa commodo velit, ut aliquam massa eros nec
          ante. Sed interdum nunc vitae lectus semper ornare. Cras id euismod
          magna. Phasellus odio magna, porta eu odio eget, commodo lobortis
          arcu. Cras eu mauris cursus, volutpat arcu sed, pretium dolor.
          Suspendisse commodo consectetur nulla ac eleifend. Ut nec congue
          turpis. Ut in neque lacinia, consequat quam eget, elementum ante.
          Nullam molestie dignissim ex eget vulputate.
          <br />
          <br />
          Curabitur nisi dui, ultricies at magna eget, convallis scelerisque
          nibh. Donec mollis dui quam. Fusce porttitor enim eget enim malesuada
          aliquet. Curabitur rhoncus, eros ac suscipit gravida, risus diam
          consequat sapien, et tempor est velit sit amet turpis. Cras tincidunt
          risus risus, ac maximus massa consectetur vel. Donec aliquam nibh
          arcu, non facilisis nunc pretium et. Nullam nisi nunc, pulvinar et
          odio eget, dapibus condimentum dui. Nullam sagittis est nec leo porta
          hendrerit. Etiam ornare sollicitudin faucibus. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Sed imperdiet, nulla a blandit condimentum, enim quam
          volutpat ligula, id ornare metus velit nec lacus.
          <br />
          <br />
          Non curabitur gravida arcu ac tortor dignissim convallis aenean et.
          Fermentum et sollicitudin ac orci phasellus. Auctor eu augue ut lectus
          arcu bibendum at. Orci sagittis eu volutpat odio facilisis. Dolor
          purus non enim praesent. Orci porta non pulvinar neque laoreet
          suspendisse interdum consectetur. Enim nunc faucibus a pellentesque
          sit. Nunc pulvinar sapien et ligula ullamcorper malesuada proin
          libero. Etiam non quam lacus suspendisse faucibus interdum posuere
          lorem ipsum. Molestie at elementum eu facilisis sed. Nunc pulvinar
          sapien et ligula ullamcorper. Volutpat maecenas volutpat blandit
          aliquam etiam erat. Nulla aliquet enim tortor at auctor urna nunc.
          <br />
          <br />
          Arcu risus quis varius quam quisque id diam vel. Turpis egestas
          integer eget aliquet. Mauris augue neque gravida in fermentum et
          sollicitudin ac orci. Cras semper auctor neque vitae tempus quam
          pellentesque nec nam. Aenean sed adipiscing diam donec adipiscing
          tristique risus. In tellus integer feugiat scelerisque varius. Eu
          lobortis elementum nibh tellus molestie. Ornare suspendisse sed nisi
          lacus sed viverra. At lectus urna duis convallis convallis. Iaculis at
          erat pellentesque adipiscing commodo elit at. Ut eu sem integer vitae
          justo eget magna fermentum iaculis. Netus et malesuada fames ac turpis
          egestas maecenas pharetra convallis. Volutpat ac tincidunt vitae
          semper quis lectus nulla. Hendrerit gravida rutrum quisque non tellus.
          Sagittis eu volutpat odio facilisis mauris. Dolor sed viverra ipsum
          nunc aliquet bibendum enim. Eget nunc scelerisque viverra mauris in
          aliquam sem fringilla ut. Porta lorem mollis aliquam ut porttitor leo.
          Proin libero nunc consequat interdum varius sit amet. Velit ut tortor
          pretium viverra suspendisse potenti nullam ac.
          <br />
          <br />
          Viverra adipiscing at in tellus integer feugiat scelerisque varius.
          Nisi porta lorem mollis aliquam ut porttitor leo a diam. Est sit amet
          facilisis magna etiam tempor orci eu lobortis. Fringilla phasellus
          faucibus scelerisque eleifend donec pretium vulputate sapien nec. Nunc
          congue nisi vitae suscipit tellus mauris a diam maecenas. Mauris
          pharetra et ultrices neque ornare aenean euismod elementum. Nisl
          tincidunt eget nullam non nisi. Tincidunt lobortis feugiat vivamus at
          augue eget arcu dictum. Amet mauris commodo quis imperdiet massa
          tincidunt. Justo donec enim diam vulputate ut pharetra sit amet. Risus
          commodo viverra maecenas accumsan lacus vel facilisis volutpat. Tortor
          pretium viverra suspendisse potenti nullam ac. Condimentum lacinia
          quis vel eros donec ac odio tempor orci. Etiam erat velit scelerisque
          in dictum non consectetur a. Odio ut sem nulla pharetra diam sit amet
          nisl suscipit. Nam libero justo laoreet sit amet cursus sit amet
          dictum. Mi proin sed libero enim. Ante metus dictum at tempor commodo
          ullamcorper. Dictum fusce ut placerat orci nulla pellentesque
          dignissim enim.
          <br />
          <br />
          Pellentesque habitant morbi tristique senectus et netus. Fermentum
          iaculis eu non diam phasellus vestibulum lorem. Malesuada fames ac
          turpis egestas maecenas pharetra. Libero id faucibus nisl tincidunt
          eget nullam. Eget mi proin sed libero enim sed. Vel pharetra vel
          turpis nunc eget lorem dolor. Nisl nisi scelerisque eu ultrices vitae.
          Interdum velit euismod in pellentesque massa placerat duis ultricies
          lacus. Convallis aenean et tortor at. Erat imperdiet sed euismod nisi
          porta lorem mollis aliquam. Habitasse platea dictumst quisque sagittis
          purus sit amet volutpat. Duis convallis convallis tellus id interdum.
          Lorem ipsum dolor sit amet consectetur. Elementum nisi quis eleifend
          quam adipiscing vitae proin. Quis hendrerit dolor magna eget est lorem
          ipsum dolor sit. Non odio euismod lacinia at. Integer feugiat
          scelerisque varius morbi enim nunc faucibus a. Orci nulla pellentesque
          dignissim enim sit. Porttitor rhoncus dolor purus non enim praesent
          elementum facilisis. Ullamcorper velit sed ullamcorper morbi
          tincidunt.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "20%",
          top: "113px",
          left: "20%",
          position: "absolute",
          display: "flex",
          justifyContent: "flex-start",
          overflow: "hidden",
          "@media screen and (max-width: 768px)": {
            top: "80px",
            left: "30px",
          },
        }}
      >
        <Select
          value={language}
          onChange={handleLanguageChange}
          variant="standard"
          MenuProps={{
            disableScrollLock: true,
            PaperProps: {
              style: {
                color: "black",
                backgroundColor: "#E0DBCF",
                maxHeight: 300,
                overflowY: "auto",
              },
            },
          }}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Spanish">Spanish</MenuItem>
          <MenuItem value="Japanese">Japanese</MenuItem>
          <MenuItem value="Mandarin">Chinese (Mandarin)</MenuItem>
          <MenuItem value="Cantonese">Chinese (Cantonese)</MenuItem>
          <MenuItem value="Korean">Korean</MenuItem>
          <MenuItem value="Russian">Russian</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
