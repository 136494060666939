import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginSlice";
import signupReducer from "./slices/signupSlice";
import zipReducer from "./slices/zipSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    signup: signupReducer,
    zip: zipReducer,
  },
});

export default store;
