import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { setIsLoginOpen } from "../state/slices/loginSlice";
import { setIsSignupOpen } from "../state/slices/signupSlice";
import { useSelector, useDispatch } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();
  const isLoginOpen = useSelector((state) => state.login.isLoginOpen);
  const isSignupOpen = useSelector((state) => state.signup.isSignupOpen);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <AppBar
        id="nav-bar"
        sx={{
          alignItems: "center",
          width: "100%",
          height: "60px",
          backgroundColor: "#E0DBCF",
          color: "black",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "100",
        }}
      >
        <Box
          id="nav-bar-content"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flex="1"
          width="98%"
        >
          <Box
            id="nav-bar-content-left"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="73%"
            columnGap="3%"
            sx={{
              width: { xs: "98%", sm: "50%", md: "30%", lg: "30%", xl: "30%" },
              justifyContent: {
                xs: "space-between",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
                xl: "flex-start",
              },
            }}
          >
            <Button
              sx={{
                padding: "none",
                margin: "none",
              }}
              onClick={toggleMenu} // toggle sidebar menu on click
            >
              <MenuRoundedIcon
                sx={{
                  fontSize: "300%",
                }}
              />
            </Button>
            <Button id="DAILYBOUNTY-navbar-button" href="/">
              <Typography
                sx={{
                  width: "100%",
                  fontFamily: "Oswald,sans-serif",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: "200%",
                  letterSpacing: "2px",
                  lineHeight: "1.2",
                }}
              >
                DAILY BOUNTY
              </Typography>
            </Button>
          </Box>
          <Box
            id="nav-bar-content-right"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="73%"
            columnGap="20px"
            sx={{
              width: { xs: "42%", sm: "60%", md: "60%", lg: "60%", xl: "60%" },
              columnGap: {
                xs: "8px",
                sm: "20px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Button
              sx={{
                display: {sm: "none", md:"flex"},
                justifyContent: "center",
                width: {
                  md: "230px", 
                  lg: "230px", 
                  xl: "230px"},
                alignItems: "center",
                height: { xs: "80%", sm: "100%" },
                color: "white",
                fontSize: { xs: "90%", sm: "130%" },
                fontWeight: { xs: "bold", sm: "500" },
                fontFamily: "Oswald",
                backgroundColor: "#785a41",
                border: "1px solid #785a41",
                borderRadius: "50px",
                letterSpacing: "2px",
              }}
            >
              Add Your Business
            </Button>
            <Button
              onClick={() => dispatch(setIsSignupOpen({}))}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "300px",
                height: { xs: "80%", sm: "100%" },
                color: "white",
                fontSize: { xs: "90%", sm: "130%" },
                fontWeight: { xs: "bold", sm: "500" },
                fontFamily: "Oswald",
                backgroundColor: "#785a41",
                border: "1px solid #785a41",
                borderRadius: "50px",
                letterSpacing: "2px",
                maxWidth: "125px",
              }}
            >
              Sign Up
            </Button>
            <Button
              onClick={() => dispatch(setIsLoginOpen({}))}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "300px",
                height: { xs: "80%", sm: "100%" },
                color: "#785a41",
                fontSize: { xs: "90%", sm: "130%" },
                fontWeight: { xs: "bold", sm: "500" },
                fontFamily: "Oswald",
                border: "2px solid #785a41",
                borderRadius: "50px",
                letterSpacing: "2px",
                maxWidth: "125px",
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </AppBar>

      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={toggleMenu}
        sx={{
          position: "absolute",
        }}
      >
        {/* Overall box of the hamburger menu */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              marginTop: "25px",
            }}
          >
            <Button
              onClick={() => dispatch(setIsSignupOpen({}))}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                color: "white",
                fontSize: "130%",
                fontWeight: "bold",
                fontFamily: "Oswald",
                backgroundColor: "#785a41",
                border: "1px solid #785a41",
                borderRadius: "50px",
                letterSpacing: "2px",
                maxWidth: "125px",
              }}
            >
              Sign Up
            </Button>
            <Button
              onClick={() => dispatch(setIsLoginOpen({}))}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                color: "#785a41",
                fontSize: "130%",
                fontWeight: "bold",
                fontFamily: "Oswald",
                border: "2px solid #785a41",
                borderRadius: "50px",
                letterSpacing: "2px",
                maxWidth: "125px",
              }}
            >
              Log In
            </Button>
            <Box>
              <List>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="ham-menu-bottom-legal-link"
                    href="/legal"
                    sx={{
                      fontSize: "1em",
                    }}
                  >
                    <Box>TERMS AND CONDITIONS</Box>
                  </Button>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="ham-menu-bottom-resturant-link"
                    sx={{
                      fontSize: "1em",
                    }}
                  >
                    <Box>ADD YOUR RESTURANT</Box>
                  </Button>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    id="ham-menu-bottom-contact-link"
                    sx={{
                      fontSize: "1em",
                    }}
                    href="/impact"
                  >
                    <Box>Our Impact</Box>
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Box>

          {/* Lower box Hamburger Menu*/}
          <Box>
            <Box>
              <img
                src="https://cdn.icon-icons.com/icons2/3005/PNG/512/globe_americas_earth_icon_188225.png"
                style={{
                  width: "275px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              ></img>
              <Box textAlign="center" fontWeight="bold" fontSize="25px">
                Daily Bounty
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: "20px",
                marginBottom: "5px",
              }}
            >
              <Button id="ham-menu-bottom-contact-link" href="/contact">
                <Box>CONTACT</Box>
              </Button>
              <Button id="ham-menu-bottom-about-link" href="/about">
                <Box>ABOUT US</Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
