import { createTheme } from "@mui/material";

export const shades = {
  brown: {
    100: "#785a41",
  },
  grey: {
    100: "#333",
  },
  tan: {
    100: "#E0DBCF",
  },
};

export const theme = createTheme({
  palette: {
    // Brown
    primary: {
      main: "#785a41",
    },
    // Grey
    secondary: {
      main: "#333",
    },
    // Tan
    background: {
      main: "#E0DBCF",
      light: "#F1EADF",
      paper: "#182c2f",
    },
  },
  typography: {
    h1: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "50px",
      color: "#E0DBCF",
    },
    h2: {
      fontFamily: ["Oswald", "sans-serif"].join(","),
      fontSize: "200%",
      fontWeight: "bold",
      letterSpacing: "2px",
      color: "#333",
    },
    h3: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "40px",
      color: "#E0DBCF",
    },
    h5: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "16px",
      color: "#e0dbcf",
    },
    h6: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: "13px",
      color: "#e0dbcf",
    },
  },
});
