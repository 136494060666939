import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignupOpen: false,
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setIsSignupOpen: (state) => {
      state.isSignupOpen = !state.isSignupOpen;
    },
  },
});

export const { setIsSignupOpen } = signupSlice.actions;

export default signupSlice.reducer;
