import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { setZip } from "../state/slices/zipSlice";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
  useEffect(() => {
    document.title = "The Daily Bounty";
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [zip, setZip] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/search");
  };

  return (
    // Change out logo in future
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        marginTop: "60px",
        backgroundImage: "url('./cafe.jpg')",
        boxShadow: "inset 0 0 0 2000px rgba(0,0,0,.4)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        display="block"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box
          id="globe-img-container"
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="50px"
          paddingTop="50px"
          width="100%"
        >
          <Box
            sx={{
              height: "400px",
              overflow: "hidden",
              "@media screen and (max-width: 768px)": {
                height: "200px",
              },
            }}
          >
            <img
              src="https://cdn.icon-icons.com/icons2/3005/PNG/512/globe_americas_earth_icon_188225.png"
              alt="daily bounty logo"
              height="100%"
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "50px",
            color: "#E0DBCF",
            fontWeight: "300",
            lineHeight: "1.167",
            letterSpacing: "-0.01562em",
            textAlign: "center",
            padding: "20px",
            "@media screen and (max-width: 768px)": {
              fontSize: "40px",
            },
          }}
        >
          Deliciously Sustainable, One Bite at a Time
        </Typography>
        <center>
          <Box width={{ xs: "90%", sm: "60%" }}>
            <form
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                borderRadius: "30px",
                backgroundColor: "white",
                padding: "5px",
              }}
            >
              {/* Remove border on focus */}
              <input
                type="text"
                placeholder="Enter your 5 digit ZIP code..."
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                style={{
                  borderRadius: "30px",
                  width: "100%",
                  height: "50px",
                  border: "none",
                  paddingLeft: "15px",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "500",
                  outline: "none",
                }}
              />
              {/* Add button transition */}
              <Button
                disabled={zip.toString().length < 5}
                variant="contained"
                size="large"
                onClick={handleSubmit}
                sx={{
                  borderRadius: "50px",
                  backgroundColor: "#785a41",
                  "&:hover": {
                    backgroundColor: "#785a41",
                    filter: "brightness(90%)",
                  },
                }}
              >
                <SearchIcon />
                Search
              </Button>
            </form>
          </Box>
        </center>
      </Box>
    </Box>
  );
}
