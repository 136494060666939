import React from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  InputAdornment,
  TextField,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";

export default function Footer() {
  return (
    <Toolbar
      style={{
        position: "flex",
        flexGrow: "1",
        flexDirection: "column",
        backgroundColor: "#E0DBCF",
        minHeight: "10vh",
        color: "black",
      }}
    >
      <Box 
        display="flex" 
        flexDirection="row" 
        alignItems="start" 
        width="100%"
        >
        {/* Top Left container */}
        <Box id="footer-content"
          sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"start",
            marginLeft: {xs: "1vh", sm:"8vh"},
            marginTop:"1vh",
          }}
        >
          {/* Daily Bounty Text */}
          <Button
            href="/"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#333"
              fontSize="200%"
              fontWeight="bold"
              fontFamily="Oswald"
              letterSpacing="3px"
              marginBottom="1vh"
            >
              DAILY BOUNTY
            </Box>
          </Button>
          {/* First Row of Links */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#333"
            fontSize="100%"
            fontFamily="Oswald"
            marginLeft="1vh"
            letterSpacing="1px"
          >
            <a
              href="about"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              About Us
            </a>
            <Box display="flex" marginLeft="30px">
              <a
                href="contact"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Contact Us
              </a>
            </Box>
          </Box>

          {/* Second Row of Links */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#333"
            fontSize="100%"
            fontFamily="Oswald"
            marginLeft="1vh"
            letterSpacing="1px"
          >
            <a
              href="legal"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Legal
            </a>
            <Box display="flex" marginLeft="55px">
              <a
                href="career"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Careers
              </a>
            </Box>
          </Box>

          {/* Social Media Icon Links */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            marginTop="1vh"
            marginBottom="1vh"
          >
            <IconButton
              aria-label="facebook"
              href="https://www.facebook.com/dailybountyinc/"
              target="_blank"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              aria-label="instagram"
              href="https://www.instagram.com/dailybountyus/"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              aria-label="twitter"
              href="https://twitter.com/DailyBountyUS"
              target="_blank"
            >
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Top Right container */}
        <Box
          display="flex"
          flex="1"
          alignItems="flex-end"
          marginTop="6vh"
          marginRight="8vh"
          flexDirection="column"
        >
          {/* Subscribe Text Field */}
          <TextField
            id="input-with-icon-textfield"
            label="Get The Latest News About Daily Bounty!"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw" }, display: { xs: "none", md: "flex" }, color: "#333", fontFamily: "Oswald" }}
          />

          {/* Subscribe Button */}
          {/* <Button
            sx={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              color:"#333", 
              fontSize:"70%",
              fontWeight:"bold",
              fontFamily:"Oswald",
              border:"1px solid #333", 
              letterSpacing:"2px",
              marginTop:"1vh",
              width: "9%"
            }}
          >
            Subscribe
          </Button> */}
        </Box>
      </Box>

      {/* Horizontal Line Break */}
      <hr
        style={{
          width: "95%",
          color: "#333",
          backgroundColor: "#333",
          height: "1px",
          borderColor: "#333",
        }}
      />

      {/* Copyright Text aligned to the left */}
      <Box
        display="flex"
        // justifyContent="start"
        alignItems="start"
        marginLeft="6vh"
        width="95%"
        color="#333"
        fontSize="65%"
        letterSpacing="1px"
        fontFamily="Oswald"
        marginTop="-1vh"
      >
        <p>&copy; 2023 Daily Bounty</p>
      </Box>
    </Toolbar>
  );
}
