import React, { useEffect } from "react";
import { Box, Typography, Divider, Grid, Avatar } from "@mui/material";

export default function About() {
  useEffect(() => {
    document.title = "Daily Bounty About";
  });

  return (
    <Box
      width="100%"
      marginTop="60px"
      padding="20px"
      backgroundColor="background.main"
    >
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Divider />

      <Box marginTop="20px" textAlign="center">
        <Typography color="#785a41" variant="h5" gutterBottom>
          Our Story
        </Typography>
        <Typography variant="body1" gutterBottom>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          pharetra urna ac elit viverra, sollicitudin faucibus justo auctor.
          Quisque eget ex eleifend libero commodo luctus id quis lacus. Nulla
          consectetur semper erat. Cras ut nisi vestibulum, mattis nulla eu,
          hendrerit tellus. Duis dolor metus, elementum a ligula ac, finibus
          euismod velit. Nulla hendrerit lacinia molestie. Suspendisse vel
          suscipit est. Pellentesque nec nibh et ligula feugiat convallis vel
          vitae purus. Integer ultrices imperdiet mauris, a congue tortor
          placerat at. Praesent ac molestie arcu, eget malesuada eros. Donec
          sodales enim non felis molestie egestas. Aliquam finibus est lectus,
          vel mollis lectus rhoncus nec. Aliquam pretium metus velit, eget
          cursus magna cursus vitae.
          <br />
          <br />
          Sed pretium ex nec molestie porta. In quis dolor ex. Integer ac est
          pulvinar, tempor urna vitae, mollis massa. Curabitur nec tempor velit,
          eget commodo eros. Nulla facilisi. Nunc est eros, rhoncus vitae felis
          nec, consectetur pulvinar metus. Integer facilisis volutpat dui, non
          dictum lorem lacinia vitae. Nulla elementum sem et lacus lobortis,
          vitae gravida sapien congue. Aliquam erat volutpat. Aliquam accumsan
          egestas lacus vel semper. Nulla facilisi. Vestibulum eleifend lorem ut
          rhoncus convallis. Quisque rhoncus nec odio sed auctor.
          <br />
          <br />
          Etiam a mauris elementum, efficitur felis a, auctor ligula.
          Pellentesque in congue sapien. Nam accumsan orci vitae odio
          consectetur tempor. Aenean suscipit dictum justo, id convallis lacus
          elementum ut. Duis eu quam ut metus rutrum condimentum. Donec finibus
          et ligula nec placerat. Etiam et lacus nec magna mattis iaculis. Sed
          ornare dolor at magna gravida, sit amet fringilla nisl pretium.
          Vestibulum sapien dolor, mattis mollis ipsum vel, varius ullamcorper
          odio. Duis malesuada diam ac sapien rutrum consectetur. Quisque ut
          eros eu lacus fringilla semper ut scelerisque leo. Aliquam in mi a
          eros accumsan posuere. Nam semper elit et elit varius accumsan.
          <br />
          <br />
          Nam ac ornare est. Praesent vehicula commodo ullamcorper. Vestibulum
          pharetra imperdiet ultricies. Donec placerat luctus augue, eu laoreet
          erat gravida quis. Duis blandit pulvinar nisi in molestie. Suspendisse
          aliquam justo tellus, ac congue nisi posuere eu. In congue posuere
          ipsum, id sollicitudin massa bibendum vel. Suspendisse potenti. Donec
          hendrerit pulvinar commodo. Integer finibus fringilla velit. Etiam
          mattis leo urna, ut posuere odio congue a. Mauris eu luctus nisi, a
          viverra metus.
        </Typography>
      </Box>

      <Box
        backgroundColor="#EAE2D5"
        marginTop="80px"
        textAlign="center"
        marginLeft="10vh"
        marginRight="10vh"
      >
        <Typography color="#785a41" variant="h5" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <img
                src="https://media.licdn.com/dms/image/C5103AQFgYatju09ajw/profile-displayphoto-shrink_200_200/0/1526520377106?e=1709769600&v=beta&t=c-nbt5-IJaTgkT-ckrEY8N3fMwxcs8Rnj7Mda6SMbE0"
                style={{
                  width: "35%", // Adjust image width as needed
                  height: "10%", // Maintain aspect ratio
                  borderRadius: "50%", // To make the image circular if needed
                  marginBottom: "5px", // Space between image and text
                }}
              />
              <Box>
                <Typography variant="subtitle1">Ray Wang</Typography>
                <Typography variant="body2">Co-Founder</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <img
                src="https://media.licdn.com/dms/image/D4E03AQG1nIJehliO0Q/profile-displayphoto-shrink_200_200/0/1674256645614?e=1709769600&v=beta&t=YfYjmpS0n0z_3k7HMA5RlKPKR2HpldQ-8fjBnYfH_dw"
                style={{
                  width: "35%", // Adjust image width as needed
                  height: "10%", // Maintain aspect ratio
                  borderRadius: "50%", // To make the image circular if needed
                  marginBottom: "5px", // Space between image and text
                }}
              />
              <Box>
                <Typography variant="subtitle1">Andy Choo</Typography>
                <Typography variant="body2">Co-Founder</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <img
                src="https://media.licdn.com/dms/image/D4E03AQHg6mUFuFtUrw/profile-displayphoto-shrink_200_200/0/1669498849500?e=1709769600&v=beta&t=XQ1OMBdY1hffx-XV3lyMt9Bi3vRKLpI1TTfQbtnrx4A"
                style={{
                  width: "35%", // Adjust image width as needed
                  height: "10%", // Maintain aspect ratio
                  borderRadius: "50%", // To make the image circular if needed
                  marginBottom: "5px", // Space between image and text
                }}
              />
              <Box>
                <Typography variant="subtitle1">John Zhu</Typography>
                <Typography variant="body2">Co-Founder</Typography>
              </Box>
            </Box>
          </Grid>
          {/* Add more team members as needed */}
        </Grid>
      </Box>

      <Box marginTop="10vh" textAlign="center">
        <Typography color="#785a41" variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          For any inquiries or collaborations, please reach out to us at:{" "}
          <a href="mailto:dailybountyinc@gmail.com">dailybountyinc@gmail.com</a>
        </Typography>
      </Box>
    </Box>
  );
}
