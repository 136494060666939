import React from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  Link,
  Grid,
} from "@mui/material";
import { setIsSignupOpen } from "../state/slices/signupSlice";
import { setIsLoginOpen } from "../state/slices/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

export default function Signup() {
  const dispatch = useDispatch();
  const isSignupOpen = useSelector((state) => state.signup.isSignupOpen);

  return (
    <Dialog
      anchor="center"
      open={isSignupOpen}
      onClose={() => dispatch(setIsSignupOpen({}))}
    >
      <Card
        sx={{
          height: { xs: "80vh", sm: "70vh" },
          width: { xs: "80vw", sm: "60vw", md: "40vw", lg: "35vw", xl: "25vw" },
        }}
      >
        <Box>
          <Button
            onClick={() => dispatch(setIsSignupOpen({}))}
            sx={{ textAlign: "right", paddingTop: "5%", color: "#E0DBCF" }}
          >
            <CloseIcon />
          </Button>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="5%"
          >
            <Typography variant="h3" textAlign="center" gutterBottom>
              Create Your Account
            </Typography>
            <Grid
              container
              width="100%"
              direction={"column"}
              spacing={3}
              alignItems="center"
              paddingBottom="5%"
            >
              <Grid
                item
                sx={{ width: "70%", display: "flex", flexDirection: "row" }}
              >
                <TextField
                  label="First name"
                  variant="standard"
                  sx={{
                    width: "100%",
                    paddingRight: "10%",
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#E0DBCF",
                    },
                    "& label.Mui-focused": {
                      color: "#E0DBCF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                />
                <TextField
                  label="Last name"
                  variant="standard"
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#E0DBCF",
                    },
                    "& label.Mui-focused": {
                      color: "#E0DBCF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                />
              </Grid>
              <Grid item sx={{ width: "70%" }}>
                <TextField
                  label="Email address"
                  variant="standard"
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#E0DBCF",
                    },
                    "& label.Mui-focused": {
                      color: "#E0DBCF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "#E0DBCF",
                      width: "20vw",
                    },
                  }}
                />
              </Grid>
              <Grid item sx={{ width: "70%" }}>
                <TextField
                  label="Password"
                  variant="standard"
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#E0DBCF",
                    },
                    "& label.Mui-focused": {
                      color: "#E0DBCF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "#E0DBCF",
                      width: "20vw",
                    },
                  }}
                />
              </Grid>
              <Grid item sx={{ width: "70%" }}>
                <TextField
                  label="Confirm password"
                  variant="standard"
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#E0DBCF",
                    },
                    "& label.Mui-focused": {
                      color: "#E0DBCF",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#E0DBCF",
                    },
                  }}
                  inputProps={{
                    style: {
                      color: "#E0DBCF",
                      width: "20vw",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              size="large"
              sx={{
                borderRadius: "50px",
                backgroundColor: "#E0DBCF",
                marginBottom: "2%",
                color: "#333",
                width: { xs: "40%", sm: "35%", md: "30%" },
                "&:hover": {
                  backgroundColor: "#E0DBCF",
                  filter: "brightness(90%)",
                },
              }}
            >
              <Typography fontWeight="bold">Sign Up</Typography>
            </Button>
            <Typography
              fontWeight="bold"
              variant="h5"
              sx={{ marginBottom: "2%" }}
            >
              or
            </Typography>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                dispatch(setIsSignupOpen({}));
                dispatch(setIsLoginOpen({}));
              }}
              sx={{
                borderRadius: "50px",
                marginBottom: "2%",
                width: { xs: "40%", sm: "35%", md: "30%" },
              }}
            >
              Log in
            </Button>
            <Typography variant="h6" textAlign="center" width="50%">
              By creating your Daily Bounty account, you agree to the{" "}
              <Link href="/legal">Terms of Service</Link> and{" "}
              <Link href="/legal">Privacy Policy</Link>
            </Typography>
          </Box>
        </Box>
      </Card>
    </Dialog>
  );
}
