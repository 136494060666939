import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Legal from "./pages/Legal";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import Database from "./pages/Database";
import Search from "./pages/Search";
import Impact from "./pages/Impact";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="about" element={<About />} />
          <Route path="legal" element={<Legal />} />
          <Route path="contact" element={<Contact />} />
          <Route path="career" element={<Career />} />
          <Route path="database" element={<Database />} />
          <Route path="search" element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/database" element={<Database />} />
          <Route path="/impact" element={<Impact />} />
        </Routes>
        <Footer />
        <Login />
        <Signup />
      </BrowserRouter>
    </div>
  );
}
