import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Grid,
  TextField,
  Collapse,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Divider from "@mui/material/Divider";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HandshakeIcon from "@mui/icons-material/Handshake";

export default function Contact() {
  useEffect(() => {
    document.title = "Daily Bounty Contact";
  });

  const [openStates, setOpenStates] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleClick = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pn3achs",
        "contact_form",
        form.current,
        "aBpRN10TuMh6xHpPu"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Box id="contact-page"
      width="100vw"
      marginTop="60px"
      paddingBottom="30px"
      backgroundColor="#F1EADF"
    >
      <Box id="contact" display="flex" justifyContent="center">
        <Box
          id="contact-title"
          sx={{
            height: { xs: "150px", sm: "250px" },
            fontWeight: { xs: "bold", sm: "normal" },
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: { xs: "50px", sm: "80px", md: "100px" },
          }}
        >
          Contact Us
        </Box>
      </Box>
      <Box
        id="contact-content"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "column",
            xl: "row",
          },
        }}
      >
        <Box
          id="contact-left"
          sx={{
            width: {
              xs: "100vw",
              sm: "650px",
              md: "680",
              lg: "680",
              xl: "680",
            },
            height: { xs: "100%", sm: "100%", md: "900px" },
            display: "flex",
            margin: { xs: "0", sm: "0", md: "0", lg: "0", xl: "0 5% 0 15%" },
            flexDirection: "column",
            padding: { xs: "0", sm: "0 20px 0 20px" },
          }}
        >
          <Box
            id="contact-left-title"
            sx={{
              fontSize: "35px",
              fontWeight: "bold",
              paddingBottom: "10px",
              display: { xs: "none", sm: "flex" },
            }}
          >
            Looking for Support?
          </Box>
          <Box
            id="contact-left-subtitle"
            sx={{
              fontSize: "20px",
              paddingBottom: "30px",
              display: { xs: "none", sm: "inline-block" },
            }}
          >
            To get a quick response, please call our number at 123-456-7890, or
            email at example@email.com
          </Box>
          <Box
            id="contact-left-content-section"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              id="contact-left-grid"
              sx={{
                display: { xs: "flex", sm: "grid" },
                flexDirection: "column",
                width: { xs: "80vw", sm: "100%" },
                gridTemplateRows: { sm: "300px 300px", md: "350px 350px" },
                gridTemplateColumns: { sm: "250px, 250px", md: "300px 300px" },
                columnGap: "20px",
                rowGap: "20px",
                padding: {},
              }}
            >
              <Box
                id="help-and-support"
                border="1px solid white"
                borderRadius="25px"
                backgroundColor="#785a41"
                color="#F1EADF"
                display="flex"
                paddingLeft="2em"
                flexDirection="column"
              >
                <Box id="help-and-support-icon" marginTop="1em">
                  <SupportAgentIcon
                    style={{
                      fontSize: "70px",
                    }}
                  ></SupportAgentIcon>
                </Box>
                <Box
                  id="help-and-support-title"
                  fontSize="27px"
                  marginTop=".65em"
                >
                  Help & Support
                </Box>
                <Box
                  id="help-and-support-content"
                  marginTop="1em"
                  fontSize="18px"
                  paddingRight="2em"
                  height="6em"
                >
                  Having trouble logging in? Need to report and issue? Let the
                  Daily bounty team know!
                </Box>
                <Button
                  sx={{
                    marginRight: "2em",
                  }}
                >
                  Find out more
                </Button>
              </Box>

              <Box
                id="help-and-support"
                border="1px solid white"
                borderRadius="25px"
                backgroundColor="#785a41"
                color="#F1EADF"
                display="flex"
                paddingLeft="2em"
                flexDirection="column"
              >
                <Box id="help-and-support-icon" marginTop="1em">
                  <AttachMoneyIcon
                    style={{
                      fontSize: "70px",
                    }}
                  ></AttachMoneyIcon>
                </Box>
                <Box id="pricing" fontSize="27px" marginTop=".65em">
                  Pricing
                </Box>
                <Box
                  id="pricing-content"
                  marginTop="1em"
                  fontSize="18px"
                  paddingRight="2em"
                  height="6em"
                >
                  Explore how we price our products and contribute to a more
                  sustainable future.
                </Box>
                <Button
                  sx={{
                    marginRight: "2em",
                  }}
                >
                  Find out more
                </Button>
              </Box>

              <Box
                id="help-and-support"
                border="1px solid white"
                borderRadius="25px"
                backgroundColor="#785a41"
                color="#F1EADF"
                display="flex"
                paddingLeft="2em"
                flexDirection="column"
              >
                <Box id="help-and-support-icon" marginTop="1em">
                  <ThumbUpIcon
                    style={{
                      fontSize: "70px",
                    }}
                  ></ThumbUpIcon>
                </Box>
                <Box
                  id="help-and-support-title"
                  fontSize="27px"
                  marginTop=".65em"
                >
                  Customer Success
                </Box>
                <Box
                  id="customer-success-content"
                  marginTop="1em"
                  fontSize="18px"
                  paddingRight="2em"
                  height="6em"
                >
                  Find out more about how we can help you success in your
                  sustainble shopping
                </Box>
                <Button
                  sx={{
                    marginRight: "2em",
                  }}
                >
                  Find out more
                </Button>
              </Box>

              <Box
                id="customer-success"
                border="1px solid white"
                borderRadius="25px"
                backgroundColor="#785a41"
                color="#F1EADF"
                display="flex"
                paddingLeft="2em"
                flexDirection="column"
              >
                <Box id="help-and-support-icon" marginTop="1em">
                  <HandshakeIcon
                    style={{
                      fontSize: "70px",
                    }}
                  ></HandshakeIcon>
                </Box>
                <Box
                  id="help-and-support-title"
                  fontSize="27px"
                  marginTop=".65em"
                >
                  Partnerships
                </Box>
                <Box
                  id="partnership-content"
                  marginTop="1em"
                  fontSize="18px"
                  paddingRight="2em"
                  height="6em"
                >
                  Learn more about become a Daily Bounty partner here.
                </Box>
                <Button
                  sx={{
                    marginRight: "2em",
                  }}
                >
                  Find out more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          id="contact-right"
          sx={{
            width: {
              xs: "80vw",
              sm: "80vw",
              md: "65vw",
              lg: "680px",
              xl: "680px",
            },
            height: "900px",
            display: "flex",
            justifyContent: "center",
            alignItems: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "flex-start",
            },
            margin: { xs: "0", sm: "0", md: "0", lg: "0", xl: "0 15% 0 5%" },
            flexDirection: "column",
            paddingTop: { xs: "2em", sm: "2em", md: "0" },
          }}
        >
          <Box
            id="contact-right-title"
            fontSize="35px"
            fontWeight="bold"
            paddingBottom="10px"
          >
            Connect with a Team Member
          </Box>
          <Box
            id="contact-right-subtitle"
            fontSize="20px"
            paddingBottom="30px"
            sx={{
              display: { xs: "none", sm: "inline-block" },
            }}
          >
            Please let us know how we can help! Upon submission we will review
            your request as soon as possible.
          </Box>

          <Box id="contact-right-content" height="100%" width="100%">
            <form
              ref={form}
              onSubmit={sendEmail}
              container
              direction="column"
              justifyContent="space-between"
              sx={{
                height: "100%",
                width: "100%",
                paddingRight: { xs: "20px", sm: "0" },
              }}
            >
              <Grid item style={{ marginBottom: 20 }}>
                <TextField
                  label="Email Address"
                  name="user_email"
                  sx={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid item container spacing={2} style={{ marginBottom: 20 }}>
                <Grid item xs={6}>
                  <TextField
                    label="First name"
                    name="first_name"
                    sx={{
                      width: "100%",
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    sx={{
                      width: "100%",
                    }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item style={{ marginBottom: 20 }}>
                <TextField
                  label="Company (optional)"
                  name="company"
                  sx={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid item style={{ marginBottom: 20 }}>
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  sx={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid item style={{ marginBottom: 20 }}>
                <TextField
                  label="Subject"
                  name="subject"
                  sx={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid item style={{ marginBottom: 20 }}>
                <TextField
                  label="How can we help you?"
                  name="message"
                  sx={{
                    width: "100%",
                  }}
                  multiline
                  rows={7}
                ></TextField>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  value="Send"
                  sx={{
                    backgroundColor: "#785a41",
                    color: "white",
                  }}
                >
                  send
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>

      <Box
        id="faq"
        sx={{
          marginTop: { xs: "20px", sm: "80px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          id="faq-title"
          sx={{
            fontSize: {
              xs: "65px",
              sm: "45px",
              md: "50px",
              lg: "50px",
              xl: "50px",
            },
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          Frequently Asked Questions
        </Box>
        <Box
          id="faq-title-abv"
          sx={{
            display: { xs: "block", sm: "none" },
            fontSize: { xs: "65px", sm: "1em" },
          }}
        >
          FAQ
        </Box>
        <Box
          id="faw-subtitle"
          sx={{
            fontSize: "23px",
            marginBottom: "30px",
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          Here are some quick answers to common questions.
        </Box>
        <Box id="faq-body">
          <List>
            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(0)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="How can I reset my password?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[0] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="This is how you reset your password"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(1)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="Where can I find if my favorite resturant has Bounties?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[1] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[1]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="Check our our partners above, or search on our Homepage!"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(2)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="How can I reset my password?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[2] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[2]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="This is how you reset your password"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(3)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="How can I reset my password?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[3] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[3]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="This is how you reset your password"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(4)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="How can I reset my password?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[4] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[4]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="This is how you reset your password"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>

            <Divider></Divider>

            <ListItemButton
              onClick={() => handleClick(5)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: {
                  xs: "96vw",
                  sm: "90vw",
                  md: "80vw",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <ListItemText
                primary="How can I reset my password?"
                primaryTypographyProps={{ fontSize: "35px" }}
              />
              {openStates[5] ? <RemoveIcon /> : <AddIcon />}
            </ListItemButton>
            <Collapse in={openStates[5]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary="This is how you reset your password"
                    primaryTypographyProps={{ fontSize: "23px" }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider></Divider>
          </List>
        </Box>
      </Box>
    </Box>
  );
}
